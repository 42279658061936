import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Container } from '../components/styled';

export default ({ data }) => (
  <Layout isLawSite>
    <Container dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
  </Layout>
);

export const pageQuery = graphql`
  query LawTextById($id: String) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`;
